export default [
  {
    _name: 'CSidebarNav',
    _children: [
      {
        _name: 'CSidebarNavItem',
        name: 'Главная',
        to: '/dashboard',
        icon: 'cil-speedometer',
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Заказы',
        to: '/orders',
        icon: 'cil-flight-takeoff',
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Адресная книга',
        to: '/addresses',
        icon: 'cil-address-book',
      },
    ]
  }
]
